/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'

// Components
import Layout from 'components/layout/Layout'
import TitleDefault from 'components/elements/TitleDefault'

const NotFoundPage = () => (
  <Layout is404>
    <div className="py-5"></div>
    <div className="container py-5 mt-5">
      <TitleDefault>404</TitleDefault>
      <p>Pagina niet gevonden.</p>
    </div>
  </Layout>
)

export default NotFoundPage
